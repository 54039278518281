<template>
  <div class="newsArticle">
    <LSection>
      <template #body>
        <div class="flex justify-end mb-4">
          <Button v-if="canEdit" :is-primary="true" @click="onToggleEditing">
            <template #center>
              <span v-if="!editing">edit</span>
              <span v-else>save & show</span>
            </template>
          </Button>
        </div>
        <div class="border-b-2">
          <div v-if="!editing" class="heading1 !text-center">
            {{ title }}
          </div>
          <div v-else class="w-full mb-6 w-50">
            <label for="title">Title</label>
            <TextInput
              :tabIndex="1"
              name="title"
              :value="title"
              placeholder="Title"
              @change="onTitleChange"
            ></TextInput>
          </div>
        </div>
        <div class="text-xs pt-4 pb-12">{{ date }}</div>
        <div v-if="editing" class="w-full mb-6">
          <label for="description">Landing page card text</label>
          <TextArea
            :tabIndex="3"
            name="description"
            :value="description"
            placeholder="A very short description of the news item (~25 words)"
            @change="onDescriptionChange"
          ></TextArea>
          <div class="w-full mb-6">
            <ImageUrlInput
              v-model="imageUrl"
              :tabIndex="2"
              @input="onImageUrlChange"
              @change="onImageUrlChange"
            ></ImageUrlInput>
          </div>
        </div>

        <label v-if="editing" for="description"> Article Body </label>
        <Markdown
          v-if="news.repoId"
          :modelValue="body"
          :tabIndex="10"
          :isEditable="editing"
          name="body"
          placeholder="News body"
          @change="onBodyChange"
        ></Markdown>
      </template>
    </LSection>
  </div>
</template>

<script>
import LSection from "@/components/layout/LSection";
import { useDocument } from "@/composables/useDocument";
import { useUserPermissions } from "@/composables/useUserPermissions";
import { capitalize } from "@/lib/typeHelpers";
import dayjs from "@/plugins/dayjs";
import { computed, ref } from "vue";
import Markdown from "@/components/forms/Markdown.vue";
import TextArea from "@/components/forms/TextArea.vue";
import TextInput from "@/components/forms/TextInput.vue";
import Button from "@/components/forms/Button.vue";
import ImageUrlInput from "@/components/forms/ImageUrlInput.vue";

export default {
  name: "NewsArticle",
  components: {
    ImageUrlInput,
    Button,
    TextInput,
    TextArea,
    Markdown,
    LSection,
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { user } = useUserPermissions();
    const { news, loadNews, updateNews } = useDocument("News", props.assetId);
    const editing = ref(false);
    const canEdit = computed(() => user.value?.__isAppAdmin ?? false);

    loadNews(true);

    const title = computed(
      () => capitalize(news.value.title) || "News Article"
    );
    const imageUrl = computed(
      () => capitalize(news.value.imageUrl) || "/eqrc/placeholder.jpg"
    );
    const description = computed(() => news.value.description || "");
    const body = computed(() => news.value.body || "");
    const date = computed(() =>
      dayjs(news.value.createdAt ?? new Date()).format("D MMMM YYYY")
    );

    const onToggleEditing = () => {
      if (editing.value) {
        try {
          const update = {
            title: news.value.title,
            description: news.value.description,
            imageUrl: news.value.imageUrl,
            body: news.value.body,
          };
          news.value = news.value || "<br>";
          updateNews(update);
        } catch (err) {
          console.log(3, "error updating news item", err);
        }
      }

      editing.value = !editing.value;
    };

    return {
      date,
      title,
      description,
      imageUrl,
      body,

      news,

      canEdit,
      editing,
      onToggleEditing,
      onDescriptionChange: (value) => (news.value.description = value),
      onImageUrlChange: (value) => (news.value.imageUrl = value),
      onTitleChange: (value) => (news.value.title = value),
      onBodyChange: (value) => (news.value.body = value),
    };
  },
};
</script>

<style scoped></style>
